import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

//NPM
import Loader from 'react-loader-spinner'
import axios from 'axios'
import styled from 'styled-components'

import './checkout-form.css'

const baseUrl = 'https://nameless-shore-28390.herokuapp.com'
// const baseUrl = 'http://localhost:5000'


const style = {
    base: {
        iconColor: '#c4f0ff',
        color: '#0e0e0e;',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '20px',
        fontSmoothing: 'antialiased',

        ':-webkit-autofill': {
            color: '#fce883',
        },
        '::placeholder': {
            color: '#87BBFD',
        },
    },
    invalid: {
        iconColor: 'red',
        color: 'red',
    },
}

const Spinner = styled.div`
    text-align: center;
    margin: 15px 0;
    font-weight: 100;
    font-size: 14px;
`

class CardDetailsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }

        this.submit = this.submit.bind(this);

    }

    componentDidMount() {
    }


    async submit(ev) {

        //STEPS:
        //Create a new token using the details
        //Create a new customer from the token
        //Attach that customer ID to the user object. Will probably need to be done using PARSE API as the master key is needed

        console.log(this.props)

        this.setState({ loading: true })

        // User clicked submit
        console.log("Creating token")
        let token = await this.props.stripe.createToken({ userId: this.props.userId });
        console.log(token)

        //Check if there was an error in creating the token
        if (token.error) {
            this.setState({ loading: false })
            alert(token.error.message)
            return
        }

        //We need to remove the authorization header in order to sent requet to payment api
        delete axios.defaults.headers.common["Authorization"]

        //Create a customer and get the token, ensure to send the token ID
        const customerResponse = await axios.post(`${baseUrl}/create-customer`, {
            tokenId: token.token.id,
            userId: this.props.userId

        });

        // alert(customerResponse.data.id)

        //Send this data to RN
        if (typeof window !== "undefined") {
            window.ReactNativeWebView.postMessage(customerResponse.data.id, "*")
        }
        //Add the customer ID to the user


    }

    render() {
        return (
            <div className="checkout">
                <CardElement
                    hidePostalCode />
                <button onClick={this.submit}>Save</button>
                {this.state.loading && <Spinner><Loader type="Oval" color="#60e05c" height="50" width="50" />Saving...</Spinner>}
            </div>
        );
    }
}

export default injectStripe(CardDetailsForm);