import React, { Component } from 'react'
import './navbar.css'
import { Link } from 'gatsby'
import axios from 'axios'

class Navbar extends React.PureComponent {
  state = {}

  componentDidMount() {
    const AUTH_TOKEN = localStorage.getItem('wdptoken')

    axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`
  }

  render() {
    if (this.props.noMenu) {
      return (
        <div />
      )
    }
    return (
      <div className="Navbar">
        <header className="navbar flex-column navbar-expand">
          <div className="navbar-brand">
            <Link to="/">
              <img src={require('../images/navlogo.png')} />
            </Link>
          </div>
          {!this.props.noMenu &&
            <div className="navbar-nav-scroll">
              <ul className="flex-row navbar-nav">
                <li className="nav-item">
                  <Link
                    activeStyle={{ color: '#572224', fontFamily: 'Avenir-Heavy' }}
                    className="nav-link"
                    to="/"
                  >
                    Home
                </Link>
                </li>
                <li className="nav-item">
                  <Link
                    getProps={({ isPartiallyCurrent }) => {
                      return isPartiallyCurrent
                        ? { className: 'nav-link active' }
                        : null
                    }}
                    activeStyle={{ color: '#572224', fontFamily: 'Avenir-Heavy' }}
                    className="nav-link"
                    to="/events"
                  >
                    Browse Events
                </Link>
                </li>

                {/* <li className="nav-item">
                  <Link
                    getProps={({ isPartiallyCurrent }) => {
                      return isPartiallyCurrent
                        ? { className: 'nav-link active' }
                        : null
                    }}
                    activeStyle={{ color: '#572224', fontFamily: 'Avenir-Heavy' }}
                    className="nav-link"
                    to="/my-tickets"
                  >
                    My Tickets
                </Link>
                </li> */}
                {/* <li className="nav-item"><Link activeStyle={{color: "#572224", fontFamily: "Avenir-Heavy"}} className="nav-link" to="/tickets">Ticketing</Link></li> */}
                {/* <li className="nav-item"><Link activeStyle={{color: "#572224", fontFamily: "Avenir-Heavy"}} className="nav-link" to="/join">Join</Link></li> */}
                {/* <li className="nav-item">
                <Link
                  getProps={({ isPartiallyCurrent }) => {
                    return isPartiallyCurrent
                      ? { className: 'nav-link active' }
                      : null
                  }}
                  activeStyle={{ color: '#572224', fontFamily: 'Avenir-Heavy' }}
                  className="nav-link"
                  to="/dashboard/"
                >
                  Dashboard
                </Link>
              </li> */}
                <li className="nav-item">
                  <Link
                    // style={{ color: '#d4333b' }}
                    activeStyle={{ color: '#572224', fontFamily: 'Avenir-Heavy' }}
                    className="nav-link"
                    to="/events/add"
                  >
                    Add an Event
                </Link>
                </li>
                <li className="nav-item">
                  <Link
                    getProps={({ isPartiallyCurrent }) => {
                      return isPartiallyCurrent
                        ? { className: 'nav-link active' }
                        : null
                    }}
                    activeStyle={{ color: '#572224', fontFamily: 'Avenir-Heavy' }}
                    className="nav-link"
                    to="/beta"
                  >
                    Beta
                </Link>
                </li>
              </ul>
            </div>
          }
        </header>
      </div>
    )
  }
}

export default Navbar
