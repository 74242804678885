import React, { Component } from 'react'
import './footer.css'

import styled from 'styled-components'

const FooterContainer = styled.div`
  border-top: 1px solid #eee;
  background-color: white;
  padding: 20px 0 40px 0;
  /*spans the full width*/
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`
const FooterContent = styled.div``
const CompanyInfo = styled.p`
  text-align: center;
  color: grey;
  opacity: 0.85;
  font-size: 0.8rem;
`
const CompanyLinks = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0 10px;
  flex-wrap: nowrap;
  overflow: scroll;
`
const CompLink = styled.li`
  color: 'white' !important;
  margin: 0 10px;
  white-space: nowrap;
  font-size: 0.9rem;
`
class Footer extends Component {
  render() {
    return (
      <FooterContainer>
        <FooterContent>
          <CompanyInfo>© 2019 WhereDPump</CompanyInfo>
          {/* <CompanyLinks>
            <CompLink>Download for iOS</CompLink>
            <CompLink>Download for Android</CompLink>
            <CompLink>Upcoming Events</CompLink>
            <CompLink>Terms</CompLink>
            <CompLink>Privacy</CompLink>
          </CompanyLinks> */}
        </FooterContent>
      </FooterContainer>
    )
  }
}

export default Footer
