import React, { Component } from 'react';
import styled from 'styled-components'
import SEO from '../components/seo'
import { Elements, StripeProvider } from 'react-stripe-elements';
import Layout from '../components/layout';
import { FaStripe } from 'react-icons/fa'

import CardDetailsForm from '../components/card-details-form';
import { Link } from 'gatsby';


const Container = styled.div`
    /* padding: 10px; */
    background-color: white;
`
const Title = styled.h1`
    margin-bottom: 10px;
`
const Copy = styled.p`
    font-size: 0.9rem;
`
const PaymentDisclaimer = styled.p`
    text-align: center;
    color: lightgray;
    padding: 0 10%;
    margin: 0 auto;
    max-width: 500px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
`
class TestAddCardPage extends Component {
    state = {
        userId: ""
    }

    componentDidMount() {

        //Get the user ID from url params, this will be sent from the React Native app
        const userId = this.props.location.search.substr(4, 10)
        this.setState({ userId })

    }

    render() {

        if (this.props.location.search) {
            return (
                <Layout noMenu>
                    <SEO title="Add a Card" />
                    <StripeProvider apiKey="pk_test_sAqWADTN9beGyfnIt0ZXOwuA">
                        <Container>
                            <Copy>
                                Enter your details below. For faster checkout in the future, this card will be saved to your profile.
                            </Copy>
                            <Elements>
                                <CardDetailsForm userId={this.state.userId} />
                            </Elements>
                            <FaStripe style={{ textAlign: 'center', display: 'block', margin: '0 auto', fontSize: '90px', color: 'lightgray' }} />
                            <PaymentDisclaimer>Powered by Stripe, an industry leader in online payments</PaymentDisclaimer>
                        </Container>

                    </StripeProvider>
                </Layout>
            );

        } else {
            return (
                <Layout>How did you get here? Go <Link to="/">home</Link></Layout>
            )
        }

    }
}

export default TestAddCardPage;